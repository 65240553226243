.container_home {
    display: flex;
    flex: 1;
    padding: 0;
    flex-direction: column;
  }
  
  .top_home {
    height: 0px;
  }
  
  .body_home {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }


  .button_reface{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    width: 50%;
    height: 50px;
    text-align: center;
    align-content: center;
    background-color: #0368FF;
    border-radius: 5px;
    color: #F2F0FF;
  }
  
  .main_select_image{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
    padding: 24px;
    border: 1.5px dotted #F2F0FF;
  }

  .input_hide {
    display: none;
  }

  .image_select {
    width: 100px;
    height: 50px;
    background-color: #0368FF;
    border-radius: 5px;
    cursor: pointer;
    object-fit: 'cover';
    object-position: 'center';
    padding: 10px;
  }

  .text_title_select_image{
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
    align-items: center;
    text-align: center;
    color: #F2F0FF;
    cursor: pointer;
  }

  .main_select_face{
    width: 50%;
    display: flex;
    border: 1.5px solid #0368FF;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;
    border-radius: 8px;
    flex-direction: row;
  }

  .image_result{
    width: 100%;
    border: 1.5px dotted #F2F0FF;
    margin-top: 16px;

  }

  .div3 {
    height: 100px;
    align-self: flex-end; /* đặt div này ở dưới cùng của container */
  }

  /* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .main_select_image {
    display: flex; 
    align-items: center;
    flex-direction: column;
  }

  .image_result{
    width: 100%;
  }

  .main_select_face{
    width: 100%;
  }

  .button_reface{
    width: 100%;
  }

  .image_select {
    width: 100px;
    height: 50px;
  }
}