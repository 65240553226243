body {
  margin: 0px;
  padding: 0px;
  background-color: #221C3E;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root_view {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container_main {
  height: 100vh;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.header {
  height: 50px;
  width: 100%;
}

.content {
  flex: 1; 
  /* div này sẽ mở rộng để lấp đầy phần còn lại của container */
}

.footer {
  flex: 1; 
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 1280px) {
  .container_main {
    width: 80%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .container_main {
    width: 96%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}
